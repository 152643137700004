<template>
  <button class="icon-btn" :disabled="disabled">
    <BaseIcon :icon="icon" :height="height" :width="width" />
  </button>
</template>

<script lang="ts">
import { PropType } from "vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";

export default defineNuxtComponent({
  components: { BaseIcon },

  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    icon: {
      type: String as PropType<string>,
      required: true,
    },
    height: {
      type: String as PropType<string>,
      default: "24",
    },
    width: {
      type: String as PropType<string>,
      default: "24",
    },
  },

  setup() {
    return {};
  },
});
</script>
